<template>
  <v-container fluid>
    <v-toolbar color="secondary" flat>
      <v-toolbar-title>Reports</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="4" class="sm-4 pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" flat dark>
          <v-toolbar-title class="subtitle-2">Call completion</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn
            text
            to="/reports/call-completion"
            class="overline font-weight-bold"
            color="accent"
          >Overview</v-btn>
          <v-card-text class="pt-0 pb-1">An overview report of call completion</v-card-text>

          <v-btn
            text
            to="/reports/call-completion-per-user"
            class="overline font-weight-bold"
            color="accent"
          >By sales rep</v-btn>
          <v-card-text class="pt-0 pb-1">
            Call completion report grouped per sales
            representative
          </v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per region</v-btn>
          <v-card-text class="pt-0 pb-1">Call completion report grouped per region</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per sub region</v-btn>
          <v-card-text class="pt-0 pb-1">Call completion report grouped per sub region</v-card-text>

          <v-btn
            text
            to="/calls"
            class="overline font-weight-bold"
            color="accent"
          >Per region & sales rep</v-btn>
          <v-card-text class="pt-0 pb-1">
            Call completion report grouped per region and per sales
            representative
          </v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per outlet</v-btn>
          <v-card-text class="pt-0 pb-1">Call completion report grouped per outlet</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" dark flat>
          <v-toolbar-title class="subtitle-2">Generated orders</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per sales rep</v-btn>
          <v-card-text class="pt-0 pb-1">
            Orders collected vs orders accepted by each sales
            representative
          </v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per outlet</v-btn>
          <v-card-text class="pt-0 pb-1">Orders collected vs orders accepted per outlet</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per region</v-btn>
          <v-card-text class="pt-0 pb-1">Orders collected vs orders accepted per region</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per sub region</v-btn>
          <v-card-text class="pt-0 pb-1">Orders collected vs orders accepted per sub region</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" dark flat>
          <v-toolbar-title class="subtitle-2">Outlets</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn
            text
            to="/reports/mapped-outlets"
            class="overline font-weight-bold"
            color="accent"
          >Outlets mapped</v-btn>
          <v-card-text class="pt-0 pb-1">All mapped outlets</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" dark flat>
          <v-toolbar-title class="subtitle-2">Sales</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Sales overview</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per product (SKU)</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per brands</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per brand</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per region</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per region</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per sub region</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per sub region</v-card-text>

          <v-btn
            text
            to="/calls"
            class="overline font-weight-bold"
            color="accent"
          >Per sales representatives</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per sales representatives</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per outlets</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per outlets</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per product</v-btn>
          <v-card-text class="pt-0 pb-1">Total sales per product (SKU)</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" dark flat>
          <v-toolbar-title class="subtitle-2">Staff performance</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Check in & out</v-btn>
          <v-card-text class="pt-0 pb-1">Sales reps daily start and close time</v-card-text>

          <v-btn
            text
            to="/calls"
            class="overline font-weight-bold"
            color="accent"
          >Conversion rate report</v-btn>
          <v-card-text class="pt-0 pb-1">Rate of order generation per call</v-card-text>

          <v-btn
            text
            to="/calls"
            class="overline font-weight-bold"
            color="accent"
          >Performance summary</v-btn>
          <v-card-text class="pt-0 pb-1">Daily performance summary</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="pt-1 pb-1">
        <v-toolbar class="pt-0 pb-0" color="accent" dark flat>
          <v-toolbar-title class="subtitle-2">Surveys</v-toolbar-title>
        </v-toolbar>
        <v-card height="400px" class="scroll">
          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Summary</v-btn>
          <v-card-text class="pt-0 pb-1">Survey responses summary</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per outlets</v-btn>
          <v-card-text class="pt-0 pb-1">Survey responses per outlets</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Per schedule</v-btn>
          <v-card-text class="pt-0 pb-1">Survey report per schedules</v-card-text>

          <v-btn text to="/calls" class="overline font-weight-bold" color="accent">Gallery report</v-btn>
          <v-card-text class="pt-0 pb-1">Images captured during surveys</v-card-text>

          <v-btn
            text
            to="/calls"
            class="overline font-weight-bold"
            color="accent"
          >Responses (Takes time to load)</v-btn>
          <v-card-text class="pt-0 pb-1">Survey responses summary</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      // surveys: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Rules", value: "rules.length" },
        { text: "Schedules", value: "schedules.length" },
        { text: "Status", value: "status" },
        { text: "Description", value: "description" },
      ],
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
/* add scrollbar to the card element */
.scroll {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
